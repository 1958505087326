/* XXL large screens */
@media (max-width: 1670px) {

    .about-img-blue {
        width: 32vw;
      }

    .team-blue {
        width: 8vw;
      }
    
    .team-img-blue {
        width: 38vw;
      }
}

/* XL large screens */
@media (max-width: 1550px) {

    .about-img-blue {
        width: 30vw;
      }

    .team-blue {
        width: 4vw;
        margin-top: 2%;
      }
    
    .team-img-blue {
        width: 36vw;
        margin-top: 2%;
      }
}

/* XLarge screens */
@media (max-width: 1440px) {

    .about-img-blue {
        width: 30vw;
      }

    .team-blue {
        width: 4vw;
        margin-top: 2%;
      }
    
    .team-img-blue {
        width: 36vw;
        margin-top: 2%;
      }

      .team-text {
        margin: 0 0 0 50px;
        width: 100%;
      }
}

/* Large screens */
@media (max-width: 1399px) {

    .about-img {
        background-position: top -160px left -90px;
      }    
    
    .about-img-blue {
        width: 31vw;
      }

    .team-blue {
        width: 5vw;
        margin-top: 2%;
      }
    
    .team-img-blue {
        width: 37vw;
        margin-top: 2%;
      }

    .team-text {
        margin: 0;
      }

    .team-img {
        background-position: top -20px left -250px;
      }
}

/* Large screens */
@media (max-width: 1269px) {

    .navbar-nav {
        margin-left: 10vw;
      }  
    
    .about-img-blue {
        width: 30vw;
      }
    
    .team-img-blue {
        width: 36vw;
        margin-top: 2%;
      }

    .team-text {
        margin: 0 0 0 30px;
      }

}

/* Desktops and large screens */
@media (max-width: 1199px) {

    .form-section {
        margin: 90px 0 0 15px !important;
      }

    .about-bg {
        margin: 0 0 90px;
      }

    .about-img-colors {
        margin-top: 3%;
      }

    .about-img-red {
        height: 470px;
      }
    
    .about-img-blue {
        width: 29vw;
        height: 470px;
      }

    .about-img {
        width: 80%;
        height: 470px;
        background-size: 170% !important;
        background-position: top -155px left -130px;
      } 

    .about-text {
        font-size: 1.22rem;
        line-height: 2.1rem;
      }

    .practice-bg {
        padding: 30px 0 75px;
      }

    .team-blue {
        width: 5vw;
        margin-top: 3%;
      }
    
    .team-img-blue {
        width: 37vw;
        margin-top: 3%;
      }

    .team-img {
        width: 71%;
        background-size: 280% !important;
        background-position: top -10px left -290px;
      }

    .team-text>p:nth-child(3) {
        font-size: 1.22rem;
        line-height: 2.1rem;
      }

    .ribbon-title {
        font-size: 3rem;
      }
      
    .ribbon-subtitle {
        font-size: 1.5rem;
      }

}

/* Portrait tablets and small desktops */
@media (max-width: 991px) {

    .logo-section {
        width: 20vw;
      }

    .navbar-collapse {
        padding: 130px 20px 0;
        background-color: var(--dark-blue);
        max-width: 30vw;
        margin: -44px 0 0 -50px !important;
        height: 100vh;
    }

    .navbar {
      /* background-color: var(--off-white); */
      /* margin-top: 40px; */
      height: 70px;
      padding: 0.9rem 0 0.9rem 1rem;
      width: 100%;
      z-index: 9;
      position: relative;   
      font-family: var(--main-font);
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
    }

    .navbar-nav {
        margin-left: 0;
        padding-left: 10px;
    }

    .menu-link {
      line-height: 2.6rem;
      text-align: end;
    }

    .contact-menu-link {
      margin: 1.5rem 0;
      text-align: center;
    }

    .navbar-toggler {
        position: absolute;
        right: 7px;
        top: 14px;
        border: none !important;
    }

    .navbar-toggler:focus:hover:visited:active {
      border: none !important;
      box-shadow: none !important;
  }

    .navbar-toggler:focus {
      border: none !important;
      box-shadow: none !important;
  }

    .navbar-toggler-icon {
        display: block;
        color: var(--accent-red);
        background-image: none;
    }

    .myform-bg {
        padding: 8px 0 40px;
      }

    .form-section {
        margin: 90px 0 0 30px !important;
      }

    .about-img-colors {
        margin-top: 0%;
      }
      
    .about-img-red {
        width: 5vw;
        height: 950px;
      }
      
    .about-img-blue {
        background-color: transparent;
      }

    .about-img {
        margin: 0 auto;
        width: 72%;
        height: 450px;
        border-radius: 0 0 100px 100px;
        background: linear-gradient(90deg, rgba(30, 61, 84, 0), rgba(0, 0, 0, 0)), url(./assets/images/aboutus-image.jpg);
        background-size: 100% !important;
        background-position: top -260px left 0px;
      }

    .about-text {
        padding: 70px 0 0;
      }

    .team-blue {
        width: 10vw;
        margin-top: 0%;
      }
      
    .team-img-blue {
        width: 10vw;
        margin-top: 0%;
      }

    .team-img {
        display: none;
      }

    .team-text {
        margin: 0 0 0 0;
      }

    .ribbon-title {
        font-size: 2.1rem;
      }
      
    .ribbon-subtitle {
        font-size: 1.3rem;
        line-height: 1.8rem;
      }

    .myfooter-logo {
        width: 75%;
        margin-bottom: 15px;
      }

    .myfooter-menu-links {
        padding: 0;
    }

}

@media (max-width: 893px) { 
    
    .about-img-text {
        max-width: 80vw;
      }
    
    .team-img-text {
        max-width: 75vw;
      }

    .team-text {
        margin: 0;
      }

    .map-wrapper {
        max-width: 80vw;
      }
      
    .map-class {
        max-width: 75vw;
      }

    .social-media-socials-box {
        max-width: 50%;
        margin: 0 auto;
    }

    .social-media-icons {
        margin: 0 auto;
      }

}

/* Landscape phones and portrait tablets */
@media (max-width: 767.98px) {

    .navbar-collapse {
      max-width: 38vw;
      }
  
    .logo-section {
        width: 25vw;
      }
      
    .hero-bg {
        background: linear-gradient(180deg, rgba(30, 61, 84, 1), rgba(0, 0, 0, 0.2)), url(./assets/images/hero-bg.jpg);
      }

    .form-section {
        margin: 110px auto 0 !important;
      }

    .hero-title {
        text-align: center;
      }

    .hero-subtitle {
        text-align: center;
      }
    
    .form-section-row {
        justify-content: center;
    }

    .my-form-title-section {
        width: 100% !important;
    }

    .myform-bg {
        width: 100% !important;
    }

    .about-img {
        background-position: top -160px left 0px;
      }

    .about-text {
        padding: 0px 0 0;
      }

    .service-text {
        font-size: 0.9rem;
        line-height: 1.1rem;
    }

    .ribbon-container {
        max-width: 100vw !important;
    }

    .footer-styling {
        padding: 50px 0 0;
      }

    .myfooter-logo-link {
        display: flex;
        justify-content: center;
      }

    .myfootersubmit-button {
        margin: 5px auto 0;
      }

    .footer-menu-links-text {
        text-align: center;
      }

    .footer-subtitle {
        text-align: center;
      }

}


@media (max-width: 576px) { 
    
    .navbar-collapse {
      max-width: 50vw;
    }
    
    .form-section {
      max-width: 90vw;
    }  
    
    .about-img-red {
        height: 1100px;
      }
    
    .about-img {
        background-position: top -30px left 0px;
      }

    .about-text {
        padding: 15px 0 0;
      }
   
    .team-blue {
        height: 750px;
      }
      
    .team-img-blue {
        height: 750px;
      }

}


/* Portrait phones and smaller */
@media (max-width: 480px) { 
    
    .navbar-collapse {
      max-width: 60vw;
      }  
  
    .logo-section {
        width: 50vw;
      }

    .form-section {
        max-width: 90vw;
      }  

    .hero-title {
        line-height: 1.2;
      }

    .about-img-red {
        height: 1200px;
      }
    
    .about-img {
        background-size: 150% !important;
        background-position: top -150px left -80px;
      }

    .team-blue {
        height: 850px;
      }
      
    .team-img-blue {
        height: 850px;
      }

    .footer-disclaimer > div {
        max-width: 90vw;
        margin: 0 auto;
      }

    .services-square {
        border-radius: 51px 0 86px 0px;
    }
    
    .text-services-square {
        border-radius: 48px 0 83px 0px;
    }
}


/* Portrait phones and smaller */
@media (max-width: 420px) { 

    .navbar-collapse {
        max-width: 70vw;
      }  
  
    .about-img-red {
        height: 1400px;
      }
    
    .about-img {
        background-size: 150% !important;
        background-position: top -60px left -80px;
      }

    .team-blue {
        height: 1000px;
      }
      
    .team-img-blue {
        height: 1000px;
      }

    .map-class {
        max-width: 70vw;
    }
}


/* Portrait phones and smaller */
@media (max-width: 370px) { 

    .about-img-red {
        height: 1500px;
      }
    
    .about-img {
        background-size: 150% !important;
        background-position: top 0px left -80px;
      }

    .service-text {
        font-size: 0.8rem;
        line-height: 1.0rem;
        margin: 12px auto 0;
    }

    .team-blue {
        height: 1100px;
      }
      
    .team-img-blue {
        height: 1100px;
      }

    .map-class {
        max-width: 70vw;
    }
}
/* Portrait phones and smaller */

/* @media (max-width: 360px) {
    
    .hero-bg {
        height: 70%;
      }
    
    .logo-section {
        width: 60vw;
        margin: 0 auto;
        display: flex;
      }

    .navbar-logo {
        width: 100%;
        margin: 0 auto;
        max-width: 405px;
        justify-content: center;
      }

} */

/* @media (max-width: 480px) {

}

@media (max-width: 300px) {

}

@media (max-height: 500px) {

} */ 

