:root{
  --main-font: "Open Sans", sans-serif;
  --accent-font: "Playfair Display", sans-serif;
  --accent-red: #CC171D;
  --dark-red: #590A0D;
  --main-blue: #1E3D54;
  --dark-blue: #142838;
  --off-white: #F5F5F5;
  --white-white: #FFFFFF;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  width: 100%;
  margin: 0 auto;
  font-family: var(--main-font) !important;
  overflow-x: hidden;
}

/* .grecaptcha-badge {
  display: none !important;
} */

.section-title {
  font-family: var(--accent-font);
  color: var(--dark-blue);
  font-size: 2rem;
  font-weight: 550;
  margin: 55px 0 5px;
}

.section-subtitle {
  font-weight: 500;
  color: var(--dark-blue);
  font-size: 1.4rem;
  line-height: 1.3;
  margin: 0 0 65px;
}

.section-title-2 {
  font-family: var(--accent-font);
  color: var(--off-white);
  font-size: 2rem;
  font-weight: 550;
  margin: 55px 0 5px;
}

.section-subtitle-2 {
  font-weight: 500;
  color: var(--off-white);
  font-size: 1.4rem;
  line-height: 1.3;
  margin: 0 0 65px;
}


/* WHATSAPP BUTTON */
.whatsapp-button {
  position: fixed;
  bottom: 20px; /* Adjust the position from the bottom */
  right: 20px; /* Adjust the position from the right */
  width: 60px; /* Adjust the width of the button */
  height: 60px; /* Adjust the height of the button */
  background-color: #25D366; /* WhatsApp green color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000; /* Make sure the button is on top */
}

.whatsapp-button img {
  width: 30px; /* Adjust the size of the icon */
  height: 30px;
}


/* HERO BG */
.hero-bg {
  /* background-color: var(--main-blue); */
  padding: 30px 0 0;
  background: linear-gradient(90deg, rgba(30, 61, 84, 1), rgba(0, 0, 0, 0)), url(./assets/images/hero-bg.jpg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
}



/* NAVBAR */
.navbar {
  /* background-color: var(--off-white); */
  /* margin-top: 40px; */
  height: 100px;
  width: 100%;
  z-index: 9;
  position: relative;   
  font-family: var(--main-font);
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.logo-section {
  width: 15vw;
  margin-left: 2vw;
}

.navbar-logo {
  width: 100%;
  max-width: 215px;
  max-height: 185px;
}

.navbar-nav {
  margin-left: 25vw;
  width: 100%;
  justify-content: space-evenly !important;
} 

.menu-link {
  text-transform: uppercase;
  text-decoration: none;
  color: var(--off-white);
  display: block;
  font-size: 1rem;
  font-weight: 500;
}

.menu-link:hover {
  color: var(--accent-red);
  border-bottom: 1px solid var(--accent-red);
  /* text-decoration: none; */
}

.contact-menu-link {
  text-transform: uppercase;
  text-decoration: none;
  background-color: var(--accent-red);
  padding: 8px 30px;
  border-radius: 18px;
  color: var(--off-white);
  display: block;
  font-size: .9rem;
}

.contact-menu-link:hover {
  background-color: var(--dark-blue);
  /* text-decoration: none; */
}

#navbarSupportedContent > ul > li.nav-item.dropdown.show > div{
  /* background-image: url("../img/header_bg.png") !important;  */
  background-position: bottom;
  background-color: #343a40!important;
}


/* .navbar-toggler {
  padding: 0;
} */

.navbar-toggler-icon {
  display: none;
}

.my-burger-menu {
  color: var(--accent-green);
  font-size: 2rem;
}



/* COTIZA */ 
.form-section {
  margin: 90px auto 0;
}

.hero-title {
  font-family: var(--accent-font);
  font-weight: 550;
  color: var(--off-white);
  font-size: 2rem;
  line-height: 1;
}

.hero-subtitle {
  color: var(--off-white);
  font-size: 1.3rem;
  line-height: 2rem;
  margin: 10px 0;
  max-width: 550px;
}

.myform-bg {
  padding: 8px 0 20px;
}

.myform-input {
  color: var(--main-blue) !important;
  background-color: var(--off-white) !important;
  border-radius: 25px !important;
  border: none !important;
  height: calc(1.2em + .75rem + 2px);
  margin: 5px 0;
  padding: 0.1rem 1.2rem !important;
  line-height: 1rem !important;
}

.message-input{
  padding: 0.7rem 1.2rem !important;
  height: calc(3.8em + .75rem + 2px) !important;
}

.myform-input::placeholder {
  color: var(--main-blue) !important;
}

.myform-input::after {
  color: var(--accent-red);
  font-size: 2rem;
  border: none;
}

.myform-input:focus {
  color: var(--accent-red);
  background-color: var(--off-white);
  border: none;
  box-shadow: none;
}

select.myform-input option {
  background-color: white; /* Change background color when open */
  border: none;
  box-shadow: none;
}

.mysubmit-button {
  color: var(--off-white) !important;
  background-color: var(--accent-red) !important;
  height: calc(1.2em + .75rem + 2px);
  border-radius: 35px !important;
  border: none !important;
  margin-top: 10px;
  padding: 5px 35px !important;
  font-weight: 600;
}

.mysubmit-button:hover {
  color: var(--accent-red) !important;
  background-color: var(--off-white) !important;
  font-weight: 600;
}

.mycustom-label {
  color: var(--main-blue);
  background-color: var(--off-white);
  border: none;
  border-radius: 15px;
  width: 90%;
  margin: 0 auto;
  height: calc(2.5em + .75rem + 2px);
  padding: 15px 12px;
}

.mycustom-label::after {
  content: "Adjuntar"; /* Change the text for the "Browse" button */
  background-color: var(--accent-green); /* Change background color of the button */
  color: var(--off-white); /* Change text color of the button */
  border: none; /* Add border to the button */
  border-radius: 15px; /* Round the corners of the button */
  margin-left: 10px; /* Add spacing between the button and the file name */
  padding: 15px 8px; /* Add padding to the button */
  height: calc(2.5em + .75rem + 2px);
  cursor: pointer;
}



/* FORM POP UP MESSAGE */
.form__status {
  position: fixed;
  top: 140px; /* Adjust as needed */
  right: 0px; /* Adjust as needed */
  border-right: 30px solid var(--off-white);
  padding: 10px 40px;
  background-color: rgba(40, 167, 69, 0.8); /* Green color for success messages */
  color: white;
  border-radius: 15px 0 0 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
  z-index: 1000; /* Ensure it’s on top of other elements */
}

.form__status.fade-out {
  opacity: 0;
  visibility: hidden;
}



/* DOWN BUTTON */  
.mydown-arrow-button {
  background-color: var(--off-white) !important;
  width: 35px;
  height: 70px;
  margin: -35px auto 0;
  border-radius: 35px !important;
  padding: 22px 5px !important;
  transition: red 0.3s ease-in-out;
  box-shadow: 0px 0px 8px 8px rgba(0, 0, 0, 0.09);
  z-index: 25;
  position: absolute !important;
  display: block;
  left: 50%;
  transform: translateX(-50%);
}

.mydown-arrow-button > i {
  color: var(--main-blue);
  font-size: 22px; 
}

.mydown-arrow-button:hover {
  color: var(--accent-red);
}



/* ABOUT */
.about-bg {
  padding: 30px 0 0 !important;
  background: linear-gradient(90deg, rgba(30, 61, 84, 0), rgba(0, 0, 0, 0)), url(./assets/images/aboutus-bg.png);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 0 0 150px;
}

.about-content {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.about-img-colors {
  position: absolute;
  z-index: 1;
  margin-top: 2%;
}

.about-img-red {
  width: 5vw;
  height: 450px;
  background-color: var(--accent-red);
}

.about-img-blue {
  width: 33vw;
  height: 450px;
  background-color: var(--main-blue);
  border-radius: 0 0 140px 0;
}

.about-img-text {
  position: relative;
  z-index: 2;
}

.about-img {
  width: 72%;
  height: 450px;
  border-radius: 0 0 100px 0;
  background: linear-gradient(90deg, rgba(30, 61, 84, 0), rgba(0, 0, 0, 0)), url(./assets/images/aboutus-image.jpg);
  background-size: 130% !important;
  background-repeat: no-repeat !important;
  background-position: top -260px left -110px;
}

.about-text {
  font-size: 1.3rem;
  line-height: 2.5rem;
}



/* PRACTICE */
.practice-bg {
  /* background-color: var(--main-blue); */
  padding: 30px 0 90px;
  background: linear-gradient(90deg, rgba(30, 61, 84, 0.93), rgba(30, 61, 84, 0.97)), url(./assets/images/practice-bg.jpg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.section-padding {
	padding-top: 80px;
}

.center-subtitle {
    max-width: 400px;
    margin: 0 auto 60px;

}

.services-square {
    width: 98%;
    background-color: var(--off-white);
    height: 300px;
    margin: 0 auto 35px;
    border-radius: 51px 0 109px 0px;
}


.clickable {
    cursor: pointer;
}

.text-services-square {
    background-color: var(--off-white);
    height: 300px;
    margin: 0 auto 35px;
    border-radius: 48px 0 106px 0px;
}

.services-icons {
    width: 50%;
    margin: 45px 0 15px;
}

.service-title {
    font-family: var(--main-font);
    color: var(--dark-blue);
    font-size: 1.5rem;
    line-height: 1.2;
    font-weight: 600;
}

.service-text {
    font-family: var(--main-font);
    color: var(--off-white);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    width: 80%;
    margin: 30px auto 0;
    display: none;
} 

.section-bottom-padding {
    padding-bottom: 50px;
}

.no-padding {
    padding: 0 !important;
}

.services-square:hover .service-title{
    color: var(--off-white);
    margin-top: 15px;
}

.services-square:hover .services-icons {
    display: none;
}

.services-square:hover .text-services-square{
    background-color: var(--dark-red);
    padding: 15px 0 0;
    transition: 0.5s all ease-out;
}

.services-square:hover .service-text{
    display: block;
}

.services-square.active .services-icons {
    display: none;
}

.services-square.active .text-services-square {
    background-color: var(--main-blue); 
}

.services-square.active .service-title{
    color: var(--off-white);
    margin-top: 10px;
}

.services-square.active .service-text {
    display: block;
}



/* TEAM */
.team-content {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.team-colors {
  position: relative;
  z-index: 1;
  
}

.team-blue {
  width: 10vw;
  height: 500px;
  background-color: var(--main-blue);
  position: absolute;
  left: 0;
  margin-top: 2.5%;
}

.team-img-blue {
  width: 39vw;
  height: 500px;
  background-color: var(--main-blue);
  border-radius: 0 0 0 140px;
  position: absolute;
  right: 0;
  margin-top: 2.5%;
}

.team-img-text {
  position: relative;
  z-index: 2;
  margin-bottom: 90px;
}

.team-img {
  width: 75%;
  height: 500px;
  margin: 0;
  border-radius: 0 0 0 100px;
  background: linear-gradient(90deg, rgba(30, 61, 84, 0), rgba(0, 0, 0, 0)), url(./assets/images/team-image.jpg);
  background-size: 230% !important;
  background-repeat: no-repeat !important;
  background-position: top -60px left -290px;
}

.team-text {
  margin: 0;
  width: 80%;
}

.team-text>p:nth-child(1) {
  font-size: 1.7rem;
  font-weight: 550;
  line-height: 1.2rem; 
}

.team-text>p:nth-child(2) {
  color: var(--accent-red);
  font-size: 1.4rem;
  line-height: 1.5rem; 
}

.team-text>p:nth-child(3) {
  font-size: 1.3rem;
  line-height: 2.1rem;
  margin: 40px 0 0; 
}

.myteam-button {
  color: var(--off-white) !important;
  background-color: var(--accent-red) !important;
  height: calc(1.3em + .75rem + 2px);
  font-size: 1.3rem !important;
  border-radius: 15px !important;
  border: none !important;
  margin-top: 40px;
  padding: 5px 35px !important;
}

.myteam-button:hover {
  font-weight: 600;
}



/* RIBBON */
.ribbon-bg {
  /* background-color: var(--main-blue); */
  padding: 30px 0 40px;
  background: linear-gradient(90deg, rgba(204, 23, 29, 0.85), rgba(204, 23, 29, 0.85)), url(./assets/images/ribbon-bg.jpg);
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.ribbon-title {
  font-family: var(--accent-font);
  color: var(--off-white);
  font-size: 3.5rem;
  font-weight: 550;
  margin: 15px 0 5px;
}

.ribbon-subtitle {
  font-weight: 400;
  color: var(--off-white);
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0 0 15px;
}



/* ADDRESS */
.address-bg {
  /* background-color: var(--main-blue); */
  padding: 30px 0 0 !important;
  background: linear-gradient(90deg, rgba(30, 61, 84, 0), rgba(0, 0, 0, 0)), url(./assets/images/aboutus-bg.png);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: 0;
}

.map-wrapper {
  background-color: var(--off-white);
  border-radius: 35px;
  padding: 20px;
  margin: 0 0 100px;
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  box-shadow: 0px 0px 80px 40px rgba(0, 0, 0, 0.08);
}

.map-class {
  border-radius: 35px;
}


/* FOOTER */
.footer-styling {
  background-color: white;
  font-family: var(--main-font);
  padding: 80px 0 0;
  position: relative;
  z-index: 1;
}

.myfooter-logo {
  width: 35%;
  margin-bottom: 15px;
}

.social-media-icons {
  background-color: var(--off-white);
  padding: 10px;
  margin-right: 20px;
  border-radius: 45px;
}

.social-media-icons:hover {
  color: var(--dark-blue);
}

.footer-socials {
  color: var(--main-blue);
  font-size: 1.5rem;
  margin-right: 0;
}

.footer-socials:hover {
  color: var(--accent-red);
}

.footer-subtitle {
  font-family: var(--main-font);
  font-weight: 750;
  color: var(--dark-blue);
  margin-bottom: 0;
  text-align: left;
}

.myfooterform-input {
  color: var(--main-blue) !important;
  background-color: var(--off-white) !important;
  border-radius: 15px !important;
  border: 2px solid var(--main-blue) !important;
  height: calc(1.2em + .75rem + 2px);
  margin: 5px 0 10px;
  padding: 0.1rem 1.2rem !important;
  line-height: 1rem !important;
}

.myfooterform-input::placeholder {
  color: var(--main-blue) !important;
}

.myfooterform-input::after {
  color: var(--accent-red);
  font-size: 2rem;
  border: none;
}

.myfooterform-input:focus {
  color: var(--accent-red);
  background-color: var(--off-white);
  border: none;
  box-shadow: none;
}

select.myfooterform-input option {
  background-color: white; /* Change background color when open */
  border: none;
  box-shadow: none;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
}

.form-control {
  width: 100%;
  padding-right: 30px; /* Space for the chevron icon */
}

.custom-select-wrapper .fa-chevron-down {
  position: absolute;
  right: 10px; /* Adjust as needed */
  top: 45%;
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the chevron doesn't interfere with clicks */
}

.myfootersubmit-button {
  color: var(--off-white) !important;
  background-color: var(--dark-blue) !important;
  height: calc(1.2em + .75rem + 2px);
  border-radius: 15px !important;
  border: none !important;
  margin-top: 5px;
  padding: 5px 35px !important;
}

.myfootersubmit-button:hover {
  font-weight: 600;
}

.footer-menu-links {
  color: var(--dark-blue);
}

.footer-menu-links > a {
  text-decoration: none;
  color: var(--dark-blue);
}

.footer-menu-links > a:hover {
  color: var(--accent-red);
  font-weight: 600;
}

.footer-disclaimer {
  /* margin-top: 100px; */
  /* border-top: .5px solid var(--accent-green); */
  padding: 20px 0;
  background-color: var(--off-white);
  
}

.footer-disclaimer > div > p {
  color: var(--main-blue);
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.footer-disclaimer > div > p > a {
  text-decoration: none;
  color: var(--main-blue);
}

.footer-disclaimer > div > p > a:hover {
  color: var(--accent-red);
}




